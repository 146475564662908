<template>
  <div class="profile-nominee">
    <Hero>
      <div class="hero-inner" />
    </Hero>

    <v-container>
      <div class="profile-nominee__inner">
        <div class="nominee profile-nominee__block">
          <div v-if="!isLoading" class="nominee__inner">
            <div class="nominee__left">
              <div class="nominee__avatar">
                <Avatar
                  :src="user.photo && getLinkByFileName('ranks', user.photo)"
                  class="nominee__avatar-image"
                  :small="isForum22"
                />
              </div>
            </div>

            <div class="nominee__right">
              <div class="nominee-status nominee__status">
                <div class="nominee-status__left">
                  <c-img
                    :src="`assets/img/${themePath}/crown-full.svg`"
                    alt=""
                  />
                  <div v-if="isForum22" class="nominee-status__left-text">
                    {{ $t(crownText) }}
                  </div>
                </div>

                <div v-if="user && user.status" class="nominee-status__right">
                  {{ $tc(user.status.toLowerCase(), 1) | upperFirst }}
                </div>
              </div>

              <div class="nominee__name">
                {{ user.name }}
              </div>

              <div class="nominee__location">
                {{ user.country }}
                {{ user.country && user.city && ', ' }}
                {{ user.city }}
              </div>

              <template v-if="user.superiorMaster">
                <div class="nominee__master-title">
                  {{ $t('superior master') }}
                </div>

                <div class="nominee-master">
                  <!-- <div :style="{backgroundImage: `url()`}" class="nominee-master__left" /> -->

                  <div class="nominee-master__right">
                    {{ user.superiorMaster }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div v-if="user.slug" class="profile-nominee__comments">
          <Comments :entity="ENTITY_NOMINEE" :slug="user.slug" />
        </div>
      </div>
    </v-container>

    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
import store from './store'
import Hero from '@/components/Hero'
import { upperCase } from 'lodash'
import { mapActions, mapState } from 'vuex'
import EventBus from '@/EventBus'
import { getLinkByFileName } from '@/utils/mediaHelpers'
import Comments from '@/modules/Comments'
import { ENTITY_NOMINEE } from '@/services/api/comments'
import Avatar from '@/components/Avatar'
import { getThemePath } from '@/utils/common'
import businessEventService from '@/services/businessEvent'
import { THEME_FORUM_22 } from '@/constants/events'

export default {
  name: 'ProfileNominee',

  components: {
    Hero,
    Comments,
    Avatar
  },

  store,

  data () {
    return {
      isLoading: false,
      ENTITY_NOMINEE,
      currentStaticEvent: {}
    }
  },

  computed: {
    ...mapState(['user']),
    themePath () {
      return getThemePath()
    },
    isForum22 () {
      return this.currentStaticEvent.name === THEME_FORUM_22
    },
    crownText () {
      const status = this.user.status ? this.user.status.toLowerCase() : ''
      return this.$t(status.split(' ').map(e => e.substring(0, 1)).join(''))
    }
  },

  async created () {
    EventBus.subscribe('language:update', this.langChanged)

    await this.getUser()
    this.currentStaticEvent = await businessEventService.getStaticEvent()
  },

  beforeDestroy () {
    EventBus.unsubscribe('language:update', this.langChanged)
  },

  methods: {
    ...mapActions(['fetchUser']),

    async langChanged () {
      await this.getUser()
    },

    async getUser () {
      const userId = this.$route.params.id

      try {
        this.isLoading = true
        await this.fetchUser(userId)
      } catch (err) {
        if (err.response?.status === '404' || err.message === 404) {
          this.$router.push('/404')
          return
        }
        throw err
      } finally {
        this.isLoading = false
      }
    },

    upperCase,
    getLinkByFileName
  }
}
</script>

<style lang="scss">
@import "./styles";
</style>
